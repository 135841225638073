<!--
 * @Descripttion: 
 * @version: X1.1
 * @Author: 李波
 * @Date: 2020-04-24 18:07:39
 * @LastEditors: 李波
 * @LastEditTime: 2020-04-24 18:17:39
 -->
<template>
  <div class="content">
    <img src="@/assets/agent/img/beian.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.content{
  width: 100%;
  height: 100%;
}
img{
  width: 100%;
  max-height: 100%;
}
</style>